const provinces = [
    {
      label: "Tripolitania",
      value: "Tripolitania",
    },
    {
      label: "Cyrenaica",
      value: "Cyrenaica",
    },
    {
      label: "Fezzan",
      value: "Fezzan",
    },
  ];

const cities = [
  {
    label: "Tripoli",
    value: "Tripoli",
  },
  {
    label: "Benghazi",
    value: "Benghazi",
  },
  {
    label: "Misrata",
    value: "Misrata",
  },
  {
    label: "Zawiya",
    value: "Zawiya",
  },
  {
    label: "Bayda",
    value: "Bayda",
  },
  {
    label: "Gharyan",
    value: "Gharyan",
  },
  {
    label: "Tobruk",
    value: "Tobruk",
  },
]  

const countries = [
  { code: "Afghanistan", name: "أفغانستان" },
{ code: "Åland Islands", name: "جزر أولاند" },
{ code: "Albania", name: "ألبانيا" },
{ code: "Algeria", name: "الجزائر" },
{ code: "American Samoa", name: "ساموا الأمريكية" },
{ code: "Andorra", name: "أندورا" },
{ code: "Angola", name: "أنغولا" },
{ code: "Anguilla", name: "أنغويلا" },
{ code: "Antarctica", name: "القارة القطبية الجنوبية" },
{ code: "Antigua & Barbuda", name: "أنتيغوا وبربودا" },
{ code: "Argentina", name: "الأرجنتين" },
{ code: "Armenia", name: "أرمينيا" },
{ code: "Aruba", name: "أروبا" },
{ code: "Australia", name: "أستراليا" },
{ code: "Austria", name: "النمسا" },
{ code: "Azerbaijan", name: "أذربيجان" },
{ code: "Bahamas", name: "الباهاما" },
{ code: "Bahrain", name: "البحرين" },
{ code: "Bangladesh", name: "بنغلاديش" },
{ code: "Barbados", name: "بربادوس" },
{ code: "Belarus", name: "بيلاروسيا" },
{ code: "Belgium", name: "بلجيكا" },
{ code: "Belize", name: "بليز" },
{ code: "Benin", name: "بنين" },
{ code: "Bermuda", name: "برمودا" },
{ code: "Bhutan", name: "بوتان" },
{ code: "Bolivia", name: "بوليفيا" },
{ code: "Bosnia & Herzegovina", name: "البوسنة والهرسك" },
{ code: "Botswana", name: "بوتسوانا" },
{ code: "Bouvet Island", name: "جزيرة بوفيه" },
{ code: "Brazil", name: "البرازيل" },
{ code: "British Indian Ocean Territory", name: "إقليم المحيط الهندي البريطاني" },
{ code: "British Virgin Islands", name: "جزر فيرجن البريطانية" },
{ code: "Brunei", name: "بروناي" },
{ code: "Bulgaria", name: "بلغاريا" },
{ code: "Burkina Faso", name: "بوركينا فاسو" },
{ code: "Burundi", name: "بوروندي" },
{ code: "Cambodia", name: "كمبوديا" },
{ code: "Cameroon", name: "الكاميرون" },
{ code: "Canada", name: "كندا" },
{ code: "Cape Verde", name: "الرأس الأخضر" },
{ code: "Caribbean Netherlands", name: "هولندا الكاريبية" },
{ code: "Cayman Islands", name: "جزر كايمان" },
{ code: "Central African Republic", name: "جمهورية أفريقيا الوسطى" },
{ code: "Chad", name: "تشاد" },
{ code: "Chile", name: "تشيلي" },
{ code: "China", name: "الصين" },
{ code: "Christmas Island", name: "جزيرة الكريسماس" },
{ code: "Cocos (Keeling) Islands", name: "جزر كوكوس" },
{ code: "Colombia", name: "كولومبيا" },
{ code: "Comoros", name: "جزر القمر" },
{ code: "Congo - Brazzaville", name: "الكونغو - برازافيل" },
{ code: "Congo - Kinshasa", name: "الكونغو - كينشاسا" },
{ code: "Cook Islands", name: "جزر كوك" },
{ code: "Costa Rica", name: "كوستاريكا" },
{ code: "Croatia", name: "كرواتيا" },
{ code: "Cuba", name: "كوبا" },
{ code: "Curaçao", name: "كوراساو" },
{ code: "Cyprus", name: "قبرص" },
{ code: "Czechia", name: "التشيك" },
{ code: "Côte d’Ivoire", name: "ساحل العاج" },
{ code: "Denmark", name: "الدنمارك" },
{ code: "Djibouti", name: "جيبوتي" },
{ code: "Dominica", name: "دومينيكا" },
{ code: "Dominican Republic", name: "جمهورية الدومينيكان" },
{ code: "Ecuador", name: "الإكوادور" },
{ code: "Egypt", name: "مصر" },
{ code: "El Salvador", name: "السلفادور" },
{ code: "Equatorial Guinea", name: "غينيا الاستوائية" },
{ code: "Eritrea", name: "إريتريا" },
{ code: "Estonia", name: "إستونيا" },
{ code: "Eswatini", name: "إسواتيني" },
{ code: "Ethiopia", name: "إثيوبيا" },
{ code: "Falkland Islands", name: "جزر فوكلاند" },
{ code: "Faroe Islands", name: "جزر فارو" },
{ code: "Fiji", name: "فيجي" },
{ code: "Finland", name: "فنلندا" },
{ code: "France", name: "فرنسا" },
{ code: "French Guiana", name: "غويانا الفرنسية" },
{ code: "French Polynesia", name: "بولينيزيا الفرنسية" },
{ code: "French Southern Territories", name: "الأقاليم الجنوبية الفرنسية" },
{ code: "Gabon", name: "الغابون" },
{ code: "Gambia", name: "غامبيا" },
{ code: "Georgia", name: "جورجيا" },
{ code: "Germany", name: "ألمانيا" },
{ code: "Ghana", name: "غانا" },
{ code: "Gibraltar", name: "جبل طارق" },
{ code: "Greece", name: "اليونان" },
{ code: "Greenland", name: "جرينلاند" },
{ code: "Grenada", name: "غرينادا" },
{ code: "Guadeloupe", name: "جزر جوادلوب" },
{ code: "Guam", name: "غوام" },
{ code: "Guatemala", name: "غواتيمالا" },
{ code: "Guernsey", name: "غيرنزي" },
{ code: "Guinea", name: "غينيا" },
{ code: "Guinea-Bissau", name: "غينيا بيساو" },
{ code: "Guyana", name: "غيانا" },
{ code: "Haiti", name: "هايتي" },
{ code: "Heard & McDonald Islands", name: "جزيرة هيرد وجزر ماكدونالد" },
{ code: "Honduras", name: "هندوراس" },
{ code: "Hong Kong SAR China", name: "هونغ كونغ" },
{ code: "Hungary", name: "المجر" },
{ code: "Iceland", name: "آيسلندا" },
{ code: "India", name: "الهند" },
{ code: "Indonesia", name: "إندونيسيا" },
{ code: "Iran", name: "إيران" },
{ code: "Iraq", name: "العراق" },
{ code: "Ireland", name: "أيرلندا" },
{ code: "Isle of Man", name: "جزيرة مان" },
{ code: "Israel", name: "إسرائيل" },
{ code: "Italy", name: "إيطاليا" },
{ code: "Jamaica", name: "جامايكا" },
{ code: "Japan", name: "اليابان" },
{ code: "Jersey", name: "جيرسي" },
{ code: "Jordan", name: "الأردن" },
{ code: "Kazakhstan", name: "كازاخستان" },
{ code: "Kenya", name: "كينيا" },
{ code: "Kiribati", name: "كيريباتي" },
{ code: "Kosovo", name: "كوسوفو" },
{ code: "Kuwait", name: "الكويت" },
{ code: "Kyrgyzstan", name: "قرغيزستان" },
{ code: "Laos", name: "لاوس" },
{ code: "Latvia", name: "لاتفيا" },
{ code: "Lebanon", name: "لبنان" },
{ code: "Lesotho", name: "ليسوتو" },
{ code: "Liberia", name: "ليبيريا" },
{ code: "Libya", name: "ليبيا" },
{ code: "Liechtenstein", name: "ليختنشتاين" },
{ code: "Lithuania", name: "ليتوانيا" },
{ code: "Luxembourg", name: "لوكسمبورغ" },
{ code: "Macao SAR China", name: "ماكاو" },
{ code: "Madagascar", name: "مدغشقر" },
{ code: "Malawi", name: "مالاوي" },
{ code: "Malaysia", name: "ماليزيا" },
{ code: "Maldives", name: "جزر المالديف" },
{ code: "Mali", name: "مالي" },
{ code: "Malta", name: "مالطا" },
{ code: "Marshall Islands", name: "جزر مارشال" },
{ code: "Martinique", name: "مارتينيك" },
{ code: "Mauritania", name: "موريتانيا" },
{ code: "Mauritius", name: "موريشيوس" },
{ code: "Mayotte", name: "مايوت" },
{ code: "Mexico", name: "المكسيك" },
{ code: "Micronesia", name: "ميكرونيزيا" },
{ code: "Moldova", name: "مولدوفا" },
{ code: "Monaco", name: "موناكو" },
{ code: "Mongolia", name: "منغوليا" },
{ code: "Montenegro", name: "الجبل الأسود" },
{ code: "Montserrat", name: "مونتسيرات" },
{ code: "Morocco", name: "المغرب" },
{ code: "Mozambique", name: "موزمبيق" },
{ code: "Myanmar (Burma)", name: "ميانمار (بورما)" },
{ code: "Namibia", name: "ناميبيا" },
{ code: "Nauru", name: "ناورو" },
{ code: "Nepal", name: "نيبال" },
{ code: "Netherlands", name: "هولندا" },
{ code: "New Caledonia", name: "كاليدونيا الجديدة" },
{ code: "New Zealand", name: "نيوزيلندا" },
{ code: "Nicaragua", name: "نيكاراغوا" },
{ code: "Niger", name: "النيجر" },
{ code: "Nigeria", name: "نيجيريا" },
{ code: "Niue", name: "نيوي" },
{ code: "Norfolk Island", name: "جزيرة نورفولك" },
{ code: "North Korea", name: "كوريا الشمالية" },
{ code: "North Macedonia", name: "مقدونيا الشمالية" },
{ code: "Northern Mariana Islands", name: "جزر ماريانا الشمالية" },
{ code: "Norway", name: "النرويج" },
{ code: "Oman", name: "عمان" },
{ code: "Pakistan", name: "باكستان" },
{ code: "Palau", name: "بالاو" },
{ code: "Palestine", name: "فلسطين" },
{ code: "Panama", name: "بنما" },
{ code: "Papua New Guinea", name: "بابوا غينيا الجديدة" },
{ code: "Paraguay", name: "باراغواي" },
{ code: "Peru", name: "بيرو" },
{ code: "Philippines", name: "الفلبين" },
{ code: "Pitcairn Islands", name: "جزر بيتكيرن" },
{ code: "Poland", name: "بولندا" },
{ code: "Portugal", name: "البرتغال" },
{ code: "Puerto Rico", name: "بورتوريكو" },
{ code: "Qatar", name: "قطر" },
{ code: "Réunion", name: "ريونيون" },
{ code: "Romania", name: "رومانيا" },
{ code: "Russia", name: "روسيا" },
{ code: "Rwanda", name: "رواندا" },
{ code: "Samoa", name: "ساموا" },
{ code: "San Marino", name: "سان مارينو" },
{ code: "São Tomé & Príncipe", name: "ساو تومي وبرينسيب" },
{ code: "Saudi Arabia", name: "المملكة العربية السعودية" },
{ code: "Senegal", name: "السنغال" },
{ code: "Serbia", name: "صربيا" },
{ code: "Seychelles", name: "سيشيل" },
{ code: "Sierra Leone", name: "سيراليون" },
{ code: "Singapore", name: "سنغافورة" },
{ code: "Sint Maarten", name: "سانت مارتن" },
{ code: "Slovakia", name: "سلوفاكيا" },
{ code: "Slovenia", name: "سلوفينيا" },
{ code: "Solomon Islands", name: "جزر سليمان" },
{ code: "Somalia", name: "الصومال" },
{ code: "South Africa", name: "جنوب أفريقيا" },
{ code: "South Georgia & South Sandwich Islands", name: "جورجيا الجنوبية وجزر ساندويتش الجنوبية" },
{ code: "South Korea", name: "كوريا الجنوبية" },
{ code: "South Sudan", name: "جنوب السودان" },
{ code: "Spain", name: "إسبانيا" },
{ code: "Sri Lanka", name: "سريلانكا" },
{ code: "St. Barthélemy", name: "سانت بارتيليمي" },
{ code: "St. Helena", name: "سانت هيلينا" },
{ code: "St. Kitts & Nevis", name: "سانت كيتس ونيفيس" },
{ code: "St. Lucia", name: "سانت لوسيا" },
{ code: "St. Martin", name: "سانت مارتن" },
{ code: "St. Pierre & Miquelon", name: "سان بيير وميكلون" },
{ code: "St. Vincent & Grenadines", name: "سانت فنسنت وجزر غرينادين" },
{ code: "Sudan", name: "السودان" },
{ code: "Suriname", name: "سورينام" },
{ code: "Svalbard & Jan Mayen", name: "سفالبارد ويان ماين" },
{ code: "Sweden", name: "السويد" },
{ code: "Switzerland", name: "سويسرا" },
{ code: "Syria", name: "سوريا" },
{ code: "Taiwan", name: "تايوان" },
{ code: "Tajikistan", name: "طاجيكستان" },
{ code: "Tanzania", name: "تنزانيا" },
{ code: "Thailand", name: "تايلاند" },
{ code: "Timor-Leste", name: "تيمور الشرقية" },
{ code: "Togo", name: "توغو" },
{ code: "Tokelau", name: "توكيلاو" },
{ code: "Tonga", name: "تونغا" },
{ code: "Trinidad & Tobago", name: "ترينيداد وتوباغو" },
{ code: "Tunisia", name: "تونس" },
{ code: "Turkey", name: "تركيا" },
{ code: "Turkmenistan", name: "تركمانستان" },
{ code: "Turks & Caicos Islands", name: "جزر تركس وكايكوس" },
{ code: "Tuvalu", name: "توفالو" },
{ code: "Uganda", name: "أوغندا" },
{ code: "Ukraine", name: "أوكرانيا" },
{ code: "United Arab Emirates", name: "الإمارات العربية المتحدة" },
{ code: "United Kingdom", name: "المملكة المتحدة" },
{ code: "United States", name: "الولايات المتحدة" },
{ code: "Uruguay", name: "أوروغواي" },
{ code: "Uzbekistan", name: "أوزبكستان" },
{ code: "Vanuatu", name: "فانواتو" },
{ code: "Vatican City", name: "مدينة الفاتيكان" },
{ code: "Venezuela", name: "فنزويلا" },
{ code: "Vietnam", name: "فيتنام" },
{ code: "Wallis & Futuna", name: "واليس وفوتونا" },
{ code: "Western Sahara", name: "الصحراء الغربية" },
{ code: "Yemen", name: "اليمن" },
{ code: "Zambia", name: "زامبيا" },
{ code: "Zimbabwe", name: "زيمبابوي" }
];


  export {provinces, cities, countries}