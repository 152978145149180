import React from "react";
import { useLanguage } from "../LanguageContext";
const Loading = () => {
  const { language } = useLanguage();
  return (
    <div className="d-flex justify-content-center">
      <div
        className="spinner-border text-success"
        role="status"
        style={{ width: "50px", height: "50px" }}
      >
        <span className="sr-only">{language === "en" ? "Loading..." : "..تحميل"}</span>
      </div>
    </div>
  );
};

export default Loading;
