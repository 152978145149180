import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';
const ProductFilter = ({
  
  filters,
  onFilterChange,
  categories,
  uniqueColors,
  uniqueSizes,
  uniqueSleeveLengths,
  uniqueDressTypes,
  uniqueDressStyles,
  uniquePatternTypes,
  uniqueDressLengths,
  uniqueMaterials,
  // minPrice,
  // maxPrice,

}) => {
  const { language } = useLanguage();
  const onSale = language === "en" ? "On Sale" : "تخفيض";
  const loading = language === "en" ? "Loading.." : "..تحميل";
  const Size = language === "en" ? "Size" : "المقاس";

// Translation maps for categories, colors, sizes, etc.
const translations = {
  categories: {
    "Pents": "بنطلون",
    "Pajamas": "بيجامة",
    "Shalwar Kamiz": "قميص شالوار",
    "Kurta": "قميص(كرتة)",
    "Shirts": "قمصان",
    "Coat": "معطف",
    "All": "الكل",
  },
  uniqueSleeveLengths: {
    "Long Sleeve": "طويل الكم",
    "Short Sleeve": "قصير الكم",
    "ShortSleeve": "قصير الكم",
    "Half Sleeve": "نصف كم",
    "Sleeveless": "بدون الكم",
  },
  uniqueDressStyles: {
    "Work": "خاص بالعمل",
    "Casual": "العملي",
    "Elegant": "الاناقة",
    "Boho": "بوهو",
    "Cute": "لطيف",
    "Modest": "محتشم",
    "Vintage": "عتيق",
    "Street": "",
    "Party": "حفلة",
    "Romantic": "رومانسي",
    "Casual-Woman": "كاجول نسائي",
    "Gorgeous-Luxury": "رائع-فاخر",
    "Casual-Young": "كاجول شبابي",
  },
  uniqueDressTypes: {
    "Pents": "بنطلون",
  "Pajamas": "بيجامة",
  "Shalwar Kamiz": "قميص شالوار",
  "Kurta": "قميص(كرتة)",
  "Shirts": "قمصان",
  "Coat": "معطف",
  "All": "الكل",
  "Abaya": "عباية",
  "A Line": "على شكل",
  "Arabian Dress": "فساتين عربية",
  "Babydolls": "دمى الأطفال",
  "Biker": "الدراجين",
  "Biker Shorts": "شورتات الدراجين",
  "Bikinis": "بيكيني",
  "Bodysuits": "بادي",
  "Bomber": "بومبر",
  "Bottoms": "بنطلونات",
  "Cagro Pants": "بنطلونات كارجو",
  "Cami": "حمالات كتف",
  "Cardigan": "كارديغان",
  "Cape": "كاب",
  "Dress": "فستان",
  "Dress Sets": "أطقم فساتين",
  "Dressing Gown": "روب",
  "Fitted": "مفصل",
  "Fit and Flare": "مفصل من الأعلى وواسع من الأسفل",
  "Flare Leg": "واسع من الأسفل",
  "Flared": "واسع",
  "Harem/Genie": "جني نسائي",
  "Halter": "حزام خلف الرقبة",
  "Half Placket": "نصف رقبة",
  "Jogger": "بنطلون رياضي",
  "Jumpsuit": "بدلة قصيرة",
  "Kaftan": "قفطان",
  "Layered/Tiered": "متعدد الثناية",
  "Leggings": "ليقنز",
  "Mermaid": "حورية البحر",
  "Nightgowns": "ملابس النوم",
  "Nursing": "للرضاعة",
  "One Pieces & Monokinis": "أطقم قطعة واحدة ومونوكيني",
  "Overall": "أفرول",
  "Pajama Bottoms": "بنطلونات البيجامة",
  "Pajama Tops": "قمصان البيجامة",
  "Pant Sets": "أطقم بنطلونات",
  "Parachute": "بنطلونات باراشوت",
  "Pea Coat": "معطف بياقة",
  "Peplum": "بيبلوم",
  "Pencil": "قلم رصاص",
  "Pleated": "مطوي",
  "Poncho": "بونشو",
  "Puffer": "سترة منتفخة",
  "Pullovers": "كنزات",
  "Quilted": "مبطّن",
  "Regular": "عادي",
  "Robe Sets": "أطقم روب",
  "Robes": "أرواب",
  "Seamless": "بدون خياطة",
  "Sheath": "فستان ضيق",
  "Shacket": "جاكيت وقميص",
  "Shirt": "قميص",
  "Shorts": "شورتات",
  "Shorts Sets": "أطقم شورتات",
  "Skirt": "تنورة",
  "Skort": "سكورت",
  "Sleepshirts": "قمصان النوم",
  "Slip Dress": "فستان ناعم",
  "Smock": "سماك",
  "Straight": "مستقيم",
  "Straight Leg": "ساق مستقيمة",
  "Sweatpants": "بنطلونات رياضية",
  "Sweatshirt": "سويت شيرت",
  "Tank": "قميص بدون أكمام",
  "Tankinis": "تانكيني",
  "Teddy": "تيدي",
  "Tee": "تي شيرت",
  "Top": "قميص",
  "Tops": "قمصان",
  "Track Shorts": "شورتات رياضية",
  "Tapered/Carrot": "بنطلونات جزرة",
  "Tube": "تيوب",
  "Tunic": "تونيك",
  "Unitard": "يوني تارد",
  "Varsity": "فارستي",
  "Vest": "سترة بلا أكمام",
  "Wide Leg": "ساق واسعة",
  "Wide Strap": "حزام عريض",
  "Windbreaker": "سترة مضادة للرياح",
  "Wrap": "تغليف",
  "Zip Up": "سترة بسحاب"
  },
  uniquePatternTypes: {
    "Plain": "سادة",
    "Heart": "نقوش على شكل قلب",
    "Floral": "منقوش بأزهار",
    "Letter": "حرف",
    "Colorblock": "تقطيع بألوان",
    "Striped": "مخطط",
    "Cartoon": "نقش كرتوني",
    "Pliad": "مربعات",
    "Leopard Print": "نقشة نمر",
    "Graphic": "رسومي",
    "Polka Dot": "نقوش دوائر",
    "Animal": "نقشة حيوانات",
    "Slogan": "شعار او عبارة",
    "All Over Print": "نقش كامل",
    "Ditsy Floral": "نقشة زهرية صغيرة",
    "Landscape Print": "نقش مناظر طبيعية",
    "Fruit&Vegatable": "نقش فاكهة وخضروات",
    "Tie Dye": "التلوين بالعقد",
    "Tropical": "الوان استوائية",
    "Plants": "نقوش نباتات",
    "Geometric": "نقوش هندسية",
    "Zebra Stripe": "خطوط زيبرا",
    "Crocodile Print": "نقشة تماس",
    "Figure": "شكل",
    "Marble": "رخامي",
    "Paisley": "نقشة بايزلي",
    "Galaxy": "نقشة جالكسي",
    "Houndstooth": "نقشة فصوص",
    "Ombre": "لون متدرج",
    "Tribal": "نقش تراثي",
    "Flamingo": "فلامنجو",
    "Butterfly": "فراشة",
    "Patchwork": "مطرز",
    "Christmas": "كرسمس",
    "Dinosaur": "نقش ديناصور",
    "Snakeskin Print": "نقش جلد الثعبان",
    "Camo": "نقش عسكري",
    "Rainbow Stripe": "نقش قوس قزح",
    "Fish Scales": "نقوش قشور السمك",
    "Halloween": "نقوش الهالوين",
    "Pop Art Print": "نقوش البوب",
    "Scarf Print": "نقشة وشاح",
    "Chain Print": "نقشة سلسلة",
    "Fire": "نقشة اللهب",
    "Chevron": "نقشة حرف V",
    "Car": "نقشة السيارات",
    "Random Print": "نقش عشوائي",
    "Baroque": "نقش باروكي",
    "Tartan": "نقشة التارتان",
    "Flag": "علم"
  },
  uniqueDressLengths: {
    "Short": "قصير",
    "Mini": "ميني",
    "Maxi": "ماكسي",
    "Long": "طويل",
    "Regular": "عادي",
    "Crop": "قصير",
    "Midi": "متوسط الطول",
    "Knee Length": "طول الركبة",
    "Cropped": "قصير",
    "Capris": "سروال تحت الركبة",
    "Mid-Calf": "منتصف الساق",
    "Thigh Length": "الطول للفخذ",
    "Micro Crop": "قصير جدًا",
    "Mini Shorts": "شورت قصير جدًا",
    "Bermuda shorts": "شورت برمودا",
    "Extra Long": "طويل جدًا"
  },
  uniqueMaterials: {
    "Cotton": "قطن",
    "Pu Leather": "جلد صناعي",
    "Chiffon": "شيفون",
    "Lace": "الدانتيل",
    "Linen": "كتان",
    "Silk": "حرير",
    "Polyester": "بوليستر",
    "Satin": "ساتان",
    "Mesh": "شبكي",
    "Viscose": "فيسكوز",
    "Denim": "دينم",
    "Nylon": "نايلون",
    "Velvet": "مخمل",
    "Sequins": "ترتر",
    "Corduory": "كوردروي",
    "Glitter": "لامع",
    "Wool-Like Fabric": "قماش شبيه بالصوف",
    "Polyamide": "بولياميد",
    "Tweed": "توييد",
    "Metallic": "معدني",
    "Organza": "أورجانزا",
    "Suedette": "سوييدي"
  },
  uniqueColors: {
    "Brown": "بني",
    "Grey": "رمادي",
    "Aqua": "أكوا",
    "Red": "أحمر",
    "Blue": "أزرق",
    "Green": "أخضر",
    "Pink": "وردي",
    "Purple": "بنفسجي",
    "Black": "أسود",
    "White": "أبيض",
    "Yellow": "أصفر",
    "Mauve": "أرجواني",
    "Navy": "كحلي",
    "Lavender": "خزامي",
    "Teal": "أزرق مخضر",
    "Coral": "مرجاني",
    "Mint Green": "أخضر نعناعي",
    "Champagne": "شمبانيا",
    "Peach": "خوخي",
    "Sapphire": "أزرق ياقوت",
    "Rose Gold": "ذهبي وردي",
    "Burgundy": "بوردو",
    "Turquoise": "فيروزي",
    "Ivory": "عاجي",
    "Gray": "رمادي",
    "Mustard": "خردلي",
    "Beige": "بيج",
    "Orange": "برتقالي",
    "Silver": "فضي",
    "Gold": "ذهبي"
  }     
};
// Function to translate based on the current language
const translate = (type, value) => {
  if (language === "ar" && translations[type] && translations[type][value]) {
    return translations[type][value];
  }
  return value;
};

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedSleeveLengths, setSelectedSleeveLengths] = useState([]);
  const [selectedDressTypes, setSelectedDressTypes] = useState([]);
  const [selectedDressStyles, setSelectedDressStyles] = useState([]);
  const [selectedPatternTypes, setSelectedPatternTypes] = useState([]);
  const [selectedDressLengths, setSelectedDressLengths] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  // const [selectedPrice, setSelectedPrice] = useState([minPrice, maxPrice]);
 
  const handleCategoryChange = (category) => {
    const updatedCategories = [...selectedCategories];
    if (updatedCategories.includes(category)) {
      updatedCategories.splice(updatedCategories.indexOf(category), 1);
    } else {
      updatedCategories.push(category);
    }
    setSelectedCategories(updatedCategories);
    onFilterChange("category", updatedCategories);
  };
  const handleColorChange = (color) => {
    const updatedColors = [...selectedColors];

    if (updatedColors.includes(color)) {
      // Color is already selected, so remove it
      updatedColors.splice(updatedColors.indexOf(color), 1);
    } else {
      // Color is not selected, so add it
      updatedColors.push(color);
    }

    setSelectedColors(updatedColors);
    onFilterChange("color", updatedColors);
  };
  const handleSizeChange = (size) => {
    const updatedSizes = [...selectedSizes];

    if (updatedSizes.includes(size)) {
      // Size is already selected, so remove it
      updatedSizes.splice(updatedSizes.indexOf(size), 1);
    } else {
      // Size is not selected, so add it
      updatedSizes.push(size);
    }

    setSelectedSizes(updatedSizes);
    onFilterChange("dressSize", updatedSizes);
  };
  const handleSleeveLengthChange = (sleeveLength) => {
    const updatedSleeveLengths = [...selectedSleeveLengths];

    if (updatedSleeveLengths.includes(sleeveLength)) {
      // Sleeve length is already selected, so remove it
      updatedSleeveLengths.splice(
        updatedSleeveLengths.indexOf(sleeveLength),
        1
      );
    } else {
      // Sleeve length is not selected, so add it
      updatedSleeveLengths.push(sleeveLength);
    }

    setSelectedSleeveLengths(updatedSleeveLengths);
    onFilterChange("sleeveLength", updatedSleeveLengths);
  };
  const handleOnSaleChange = () => {
    const updatedOnSale = !filters.onSale;
    onFilterChange("onSale", updatedOnSale);
  };
  const handleDressStyleChange = (dressStyle) => {
    const updatedDressStyles = [...selectedDressStyles];

    if (updatedDressStyles.includes(dressStyle)) {
      updatedDressStyles.splice(updatedDressStyles.indexOf(dressStyle), 1);
    } else {
      updatedDressStyles.push(dressStyle);
    }

    setSelectedDressStyles(updatedDressStyles);
    onFilterChange('dressStyle', updatedDressStyles);
  };
  const handlePatternTypeChange = (patternType) => {
    const updatedPatternTypes = [...selectedPatternTypes];
  
    if (updatedPatternTypes.includes(patternType)) {
      // PatternType is already selected, so remove it
      updatedPatternTypes.splice(updatedPatternTypes.indexOf(patternType), 1);
    } else {
      // PatternType is not selected, so add it
      updatedPatternTypes.push(patternType);
    }
  
    setSelectedPatternTypes(updatedPatternTypes);
    onFilterChange('patternType', updatedPatternTypes);
  };
  const handleDressLengthChange = (dressLength) => {
    const updatedDressLengths = [...selectedDressLengths];

    if (updatedDressLengths.includes(dressLength)) {
      updatedDressLengths.splice(updatedDressLengths.indexOf(dressLength), 1);
    } else {
      updatedDressLengths.push(dressLength);
    }

    setSelectedDressLengths(updatedDressLengths);
    onFilterChange('dressLength', updatedDressLengths);
  };
  const handleMaterialChange = (material) => {
    const updatedMaterials = [...selectedMaterials];

    if (updatedMaterials.includes(material)) {
      updatedMaterials.splice(updatedMaterials.indexOf(material), 1);
    } else {
      updatedMaterials.push(material);
    }

    setSelectedMaterials(updatedMaterials);
    onFilterChange('material', updatedMaterials);
  };
  const handleDressTypeChange = (dressType) => {
    const updatedDressTypes = [...selectedDressTypes];
    if (updatedDressTypes.includes(dressType)) {
      // Dress type is already selected, so remove it
      updatedDressTypes.splice(updatedDressTypes.indexOf(dressType), 1);
    } else {
      // Dress type is not selected, so add it
      updatedDressTypes.push(dressType);
    }
    setSelectedDressTypes(updatedDressTypes);
    onFilterChange('dressType', updatedDressTypes);
  };

  return (
    <div className="product-filter">
      {/* onSale Fitler */}
      <div className="mb-3">
        <label className="form-check-label">
          <input
            type="checkbox"
            className="form-check-input mx-2"
            checked={filters.onSale}
            onChange={handleOnSaleChange}
          />
          {onSale}
        </label>
      </div>
      <hr />
      {/* Category filter */}
      <div className="filter-section">
      <p>{language === "en" ? "Category" : "الفئة"}</p>
        <hr />
        {categories && categories.length > 0 ? (
          categories.map((category) => (
            <div className="form-check" key={category}>
              <input
                type="checkbox"
                id={category}
                className="form-check-input"
                value={category}
                checked={selectedCategories.includes(category)}
                onChange={() => handleCategoryChange(category)}
              />
              <label htmlFor={category} className="form-check-label">
              {translate("categories", category)}
              </label>
            </div>
          ))
        ) : (
          <p>{loading}</p>
        )}
      </div>
      <hr />
      {/* Color filter */}
      <div className="filter-section">
        <p>{language === "en" ? "Color" : "اللون"}</p>
        <hr />
        {uniqueColors && uniqueColors.length > 0 ? (
          uniqueColors.map((color) => (
            <div className="form-check" key={color}>
              <input
                type="checkbox"
                id={color}
                className="form-check-input"
                value={color}
                checked={selectedColors.includes(color)}
                onChange={() => handleColorChange(color)}
              />
              <label htmlFor={color} className="form-check-label">
              {translate("uniqueColors", color)}
              </label>
            </div>
          ))
        ) : (
          <p>{loading}</p>
        )}
      </div>
      <hr />
      {/* size filter */}
      <div className="filter-section">
        <p>{Size}</p>
        <hr />
        {uniqueSizes && uniqueSizes.length > 0 ? (
          uniqueSizes.map((size) => (
            <div className="form-check" key={size}>
              <input
                type="checkbox"
                id={size}
                className="form-check-input"
                value={size}
                checked={selectedSizes.includes(size)}
                onChange={() => handleSizeChange(size)}
              />
              <label htmlFor={size} className="form-check-label">
                {size}
              </label>
            </div>
          ))
        ) : (
          <p>{loading}</p>
        )}
      </div>
      <hr />
      {/* Sleeve Length Filter */}
      <div className="filter-section">
        <p>{language === "en" ? "Sleeve Length" : "طول الكم"}</p>
        <hr />
        {uniqueSleeveLengths && uniqueSleeveLengths.length > 0 ? (
          uniqueSleeveLengths.map((sleeveLength) => (
            <div className="form-check" key={sleeveLength}>
              <input
                type="checkbox"
                id={sleeveLength}
                className="form-check-input"
                value={sleeveLength}
                checked={selectedSleeveLengths.includes(sleeveLength)}
                onChange={() => handleSleeveLengthChange(sleeveLength)}
              />
              <label htmlFor={sleeveLength} className="form-check-label">
              {translate("uniqueSleeveLengths", sleeveLength)}
              </label>
            </div>
          ))
        ) : (
          <p>{loading}</p>
        )}
      </div>
      <hr />
      {/* Dress Style Filter */}
      <div className="filter-section">
        <p>{language === "en" ? "Dress Style" : "ستايل الفستان"}</p>
        <hr />
        {uniqueDressStyles && uniqueDressStyles.length > 0 ? (
          uniqueDressStyles.map((dressStyle) => (
            <div className="form-check" key={dressStyle}>
              <input
                type="checkbox"
                id={dressStyle}
                className="form-check-input"
                value={dressStyle}
                checked={selectedDressStyles.includes(dressStyle)}
                onChange={() => handleDressStyleChange(dressStyle)}
              />
              <label htmlFor={dressStyle} className="form-check-label">
              {translate("uniqueDressStyles", dressStyle)}
              </label>
            </div>
          ))
        ) : (
          <p>{loading}</p>
        )}
      </div>
      <hr></hr>
      {/* Dress Type filter */}
      <div className="filter-section">
        <p>{language === "en" ? "Dress Type" : "نوع الفستان"}</p>
        <hr />
        {uniqueDressTypes && uniqueDressTypes.length > 0 ? (
          uniqueDressTypes.map((dressType) => (
            <div className="form-check" key={dressType}>
              <input
                type="checkbox"
                id={dressType}
                className="form-check-input"
                value={dressType}
                checked={selectedDressTypes.includes(dressType)}
                onChange={() => handleDressTypeChange(dressType)}
              />
              <label htmlFor={dressType} className="form-check-label">
              {translate("uniqueDressTypes", dressType)}
              </label>
            </div>
          ))
        ) : (
          <p>{loading}</p>
        )}
      </div>
      <hr></hr>
      {/* PatternType filter */}
      <div className="filter-section">
      <p>{language === "en" ? "Pattern Type" : "نوع النقش"}</p>
        <hr />
        {uniquePatternTypes && uniquePatternTypes.length > 0 ? (
          uniquePatternTypes.map((patternType) => (
            <div className="form-check" key={patternType}>
              <input
                type="checkbox"
                id={patternType}
                className="form-check-input"
                value={patternType}
                checked={selectedPatternTypes.includes(patternType)}
                onChange={() => handlePatternTypeChange(patternType)}
              />
              <label htmlFor={patternType} className="form-check-label">
              {translate("uniquePatternTypes", patternType)}
              </label>
            </div>
          ))
        ) : (
          <p>{loading}</p>
        )}
      </div>
      <hr></hr>
      {/* Dress Length Filter */}
    <div className="filter-section">
    <p>{language === "en" ? "Pattern Type" : "نوع النقش"}</p>
      <hr />
      {uniqueDressLengths && uniqueDressLengths.length > 0 ? (
        uniqueDressLengths.map((dressLength) => (
          <div className="form-check" key={dressLength}>
            <input
              type="checkbox"
              id={dressLength}
              className="form-check-input"
              value={dressLength}
              checked={selectedDressLengths.includes(dressLength)}
              onChange={() => handleDressLengthChange(dressLength)}
            />
            <label htmlFor={dressLength} className="form-check-label">
            {translate("uniqueDressLengths", dressLength)}
            </label>
          </div>
        ))
      ) : (
        <p>{loading}</p>
      )}
    </div>
    <hr></hr>
    {/* Material Filter */}
    <div className="filter-section">
    <p>{language === "en" ? "Material" : "قماش"}</p>
      <hr />
      {uniqueMaterials && uniqueMaterials.length > 0 ? (
        uniqueMaterials.map((material) => (
          <div className="form-check" key={material}>
            <input
              type="checkbox"
              id={material}
              className="form-check-input"
              value={material}
              checked={selectedMaterials.includes(material)}
              onChange={() => handleMaterialChange(material)}
            />
            <label htmlFor={material} className="form-check-label">
            {translate("uniqueMaterials", material)}
            </label>
          </div>
        ))
      ) : (
        <p>{loading}</p>
      )}
    </div>
    
      <hr></hr>
    </div>
  );
};

export default ProductFilter;