import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import Header from "./../components/Header";
import { login } from "./../Redux/Actions/userActions";
import { useLanguage } from "../components/LanguageContext";
const Login = ({ location, history }) => {
  const { language } = useLanguage();
  const Email = language === "en" ? "Email" : "البريد الالكتروني";
  const Password = language === "en" ? "Password" : "كلمة مرور ";
  const Login = language === "en" ? "Login" : "تسجيل الدخول";
  const createAccount = language === "en" ? "Create Account" : "إنشاء حساب جديد";
  window.scrollTo(0, 0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}
        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <input
            type="email"
            placeholder={Email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder={Password}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">{Login}</button>
          <p>
            <Link
              to={redirect ? `/register?redirect=${redirect}` : "/register"}
            >
              {createAccount}
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default Login;
