
import React, { useState } from "react";
import axios from "axios";
import apiUrl from "../../apiConf";
import { useLanguage } from "../LanguageContext";
const CalltoActionSection = () => {
  const { language } = useLanguage();
  const tips1 = language === "en" ? "Do you need more tips?" : "هل تحتاج إلى المزيد من النصائح؟";
  const tips2 = language === "en" ? "Sign up free and get the latest tips." : "اشترك مجانا واحصل على أحدث النصائح";
  const subs = language === "en" ? "Successfully subscribed" : "تم الاشتراك بنجاح";
  const subs1 = language === "en" ? "Yes. I want!" : "نعم أريد";
  const subs3 = language === "en" ? "Your Email..." : "بريدك الالكتروني";

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
       await axios.post(`${apiUrl}/api/users/subscribe`, { email });
      setSuccess(true);
      setEmail(""); // Clear the email input after successful subscription
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <div className="subscribe-section bg-with-black text-center">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="subscribe-head">
              <h2>{tips1}</h2>
              <p>{tips2}</p>
              <form className="form-section" onSubmit={handleSubmit}>
                <input
                  placeholder={subs3}
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input className="btnSub" value={subs1} name="subscribe" type="submit" />
              </form>
              {error && (
                <div className="alert alert-danger text-center alert-dismissible fade show  mx-auto mt-3" role="alert">
                {error}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              )}
              {success && (
                <div class="alert alert-success alert-dismissible fade show  mx-auto mt-3" role="alert">
                {subs}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalltoActionSection;
