import React from "react";
import { useLanguage } from "../LanguageContext";
const ContactInfo = () => {
  const { language } = useLanguage();
  const call = language === "en" ? "Call Us 24x7" : "اتصل بنا 24*7";
  const Headquarter = language === "en" ? "Headquarter" : "المقر الرئيسي";
  const address = language === "en" ? "No:1 Tweed st., three rivers, Vereeniging, South Africa 1929" : "رقم: 1 شارع تويد، ثلاثة أنهار، فيرينيجنج، جنوب أفريقيا 1929";
  const Fax = language === "en" ? "FAX" : "البريد المصور(الفاكس)";
  return (
    <div className="contactInfo container">
      <div className="row">
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h5>{call}</h5>
            <p>+27 823193998</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h5>{Headquarter}</h5>
            <p className="text-center">{address} </p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-fax"></i>
            </div>
            <h5>{Fax}</h5>
            <p>+27 8823193998</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
